import useBreakpoint from "use-breakpoint";

const BREAKPOINTS = { mobile: 0, tablet: 768, macAir: 1280, desktop: 1500, };

function App() {
  const { breakpoint } = useBreakpoint(BREAKPOINTS, "desktop");
  const frameWidth = breakpoint === "desktop" ? "100vw" : "90vw";

  if (breakpoint === "desktop" || breakpoint === "macAir") {
    return (
      <div style={{width: "100vw", height: "100vh", display: "flex", justifyContent: "center", alignItems: "center"}}>
        <iframe
          style={{ border: "1px solid rgba(0,0,0,0.1", width: frameWidth, height: "100vh", maxHeight: breakpoint === "desktop" ? "calc(100vw * 900 / 1440)" : "calc(90vw * 900 / 1440)", marginLeft: "auto", marginRight: "auto" }}
          src="https://www.figma.com/embed?embed_host=share&url=https%3A%2F%2Fwww.figma.com%2Fproto%2FnAeSBwh9lB54qNwkT8yZgw%2FLuna-MVP%3Fpage-id%3D0%253A1%26node-id%3D2%253A2%26viewport%3D241%252C48%252C0.03%26scaling%3Dscale-down-width%26starting-point-node-id%3D2%253A2%26show-proto-sidebar%3D1%26hide-ui%3D1"
          allowfullscreen
        ></iframe>
      </div>
    );
  }

  return (
    <div
      style={{
        width: "100vw",
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        textAlign: "center",
      }}
    >
      <h1 style={{ maxWidth: "90%" }}>
        💻 Switch to your laptop or PC to view this demo
      </h1>
    </div>
  );
}

export default App;
